@import "src/app/styles/assets/scss/colors";

.wrapper {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows:
          [headerTop] max-content [headerBottom] 0
          [listTop] auto [listBottom] 0
          [footerTop] auto [footerBottom];
  height: 100%;
  overflow: hidden;

  .content {
    overflow-y: auto;
    padding: 2.4rem 1.6rem 0 1.6rem;
    grid-row: listTop / listBottom;

    &.header_children {
      padding: 1.2rem 1.6rem 0 1.6rem;
    }

    &.has_step {
      //margin-top: 2.2rem;
    }
  }

  .footer {
    grid-row: footerTop / footerBottom;
    padding: 1.2rem 1.6rem 2.4rem 1.6rem;
    background-color: $white;
    display: grid;
    grid-column: 1;
    align-self: end;

    &.has_shadow {
      position: relative;
      z-index: 1;
      box-shadow: 0px -18px 5px 0px rgba(43, 43, 43, 0.00), 0px -11px 5px 0px rgba(43, 43, 43, 0.00), 0px -6px 4px 0px rgba(43, 43, 43, 0.02), 0px -3px 3px 0px rgba(43, 43, 43, 0.03), 0px -1px 2px 0px rgba(43, 43, 43, 0.03);
    }
  }
}