@import "src/app/styles/assets/scss/variables";
@import "src/app/styles/assets/scss/colors";

.sheet_header {
  margin-bottom: 2.4rem;

  .text_holder {
    display: grid;
    margin: 1.6rem 0 0 1.6rem;
    height: 3.8rem;

    &.has_subtext {
      display: grid;
      grid-gap: .4rem;
      height: 4.8rem;
    }
  }
}

.inApp_header_text {
  position: absolute;
  top: 1.6rem;
  left: 1.6rem;
  color: $black;
  font-size: 2rem;
  font-weight: 800;
  line-height: 2.8rem;
}

.close_container {
  width: 1.4rem;
  height: 1.4rem;
  padding: 1rem;
  border-radius: 10rem;
  background-color: $white;
  box-shadow: 0 2px 6px 0 rgba(43, 43, 43, 0.10);
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .inApp_header_close {
    font-family: $iconFont2;
    font-size: 1.4rem;
    line-height: 1;
    color: $black;
  }
}


.inApp_content {
  margin-bottom: var(--saib);

  .image_type_wrapper {
    .image_holder {
      border-radius: 12px 12px 0 0;
      height: 22.5rem;
      background-color: $successLightrest1;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      img {
        margin-bottom: 2rem;
      }
    }

    .content {
      border-radius: 16px 16px 0 0;
      margin-top: -2rem;
      background-color: $white;
      padding: 2rem 1.6rem 0 1.6rem;

      .context_text_holder {
        display: grid;
        margin-bottom: 2.4rem;
      }
    }
  }
}

.header_text {
  color: $black;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.6rem;
  letter-spacing: -0.2px;
}

.header_subtext {
  color: $grayDark;

  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.14px;
  min-height: 1.8rem;
}