@import "src/app/styles/assets/scss/colors";

.text {

  &.td-n {
    text-decoration: none;
  }

  &.nowrap {
    white-space: nowrap;
  }

  .ellipsize {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.fw-thin {
    font-weight: 100 !important;
  }

  &.fw-extra-light {
    font-weight: 200 !important;
  }

  &.fw-light {
    font-weight: 300 !important;
  }

  &.fw-normal {
    font-weight: 400 !important;
  }

  &.fw-medium {
    font-weight: 500 !important;
  }

  &.fw-semibold {
    font-weight: 600 !important;
  }

  &.fw-bold {
    font-weight: 700 !important;
  }

  &.fw-extra-bold {
    font-weight: 800 !important;
  }

  &.fw-black {
    font-weight: 900 !important;
  }

  &.fs-xxgiant {
    font-size: 104px !important;
  }

  &.fs-xgiant {

    font-size: 96px !important;
  }

  &.fs-giant {
    font-size: 88px !important;
  }

  &.fs-xxenormous {
    font-size: 80px !important;
  }

  &.fs-xenormous {
    font-size: 72px !important;
  }

  &.fs-enormous {
    font-size: 64px !important;
  }

  &.fs-xxhuge {
    font-size: 56px !important;
  }

  &.fs-xhuge {
    font-size: 48px !important;
  }

  &.fs-huge {
    font-size: 40px !important;
  }

  &.fs-xxlarge {
    font-size: 32px !important;
  }

  &.fs-xlarge {
    font-size: 24px !important;
  }

  &.fs-large {
    font-size: 20px !important;
  }

  &.fs-normal {
    font-size: 1.6rem !important;
  }

  &.fs-small {
    font-size: 1.4rem !important;
  }

  &.fs-xsmall {
    font-size: 1.2rem !important;
  }

  &.fs-xxsmall {
    font-size: 1.0rem !important;
  }

  &.fs-tiny {
    font-size: .8rem !important;
  }

  &.fs-xtiny {
    font-size: .6rem !important;
  }

  &.black {
    color: $black !important;
  }

  &.white {
    color: $white;
  }

  &.gray {
    color: $gray;
  }

  &.grayLight {
    color: $grayLight;
  }

  &.primary {
    color: $primary;
  }

  &.grayDarker {
    color: $grayDarker;
  }

  &.grayDark {
    color: $grayDark;
  }

  &.error {
    color: $error;
  }

  &.warning {
    color: $warning;
  }
}

.text-center {
  text-align: center !important;
}

.va-middle {
  vertical-align: middle !important;
}

.va-top {
  vertical-align: top !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}
