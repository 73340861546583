@import "src/app/styles/assets/scss/colors";


.wrapper {
  display: grid;
  grid-gap: .4rem;
  max-width: 100%;

  .input {
    max-width: 100%;
    padding: 1rem 1.2rem;
    outline: none;
    min-height: 2.2rem;
    max-height: 8.6rem;
    border-radius: 1.2rem;
    background-color: $grayLightrest1;
    border: none;
    resize: none;
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    transition: background-color .5s ease-out;

    color: $black;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.2rem;

    &.error {
      border: 1px solid $error;
      padding: .5rem 1.1rem;
    }

    &::-webkit-resizer {
      display: none;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 10px;
      height: 10px;
      background: transparent;
      cursor: se-resize;
    }

    &::placeholder {
      color: $gray;
    }

    &:disabled {
      background-color: $grayLightrest1;
      pointer-events: none;
    }
  }
}