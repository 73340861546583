@import "app/styles/assets/scss/colors";

#input_range {
  background: linear-gradient(to right, $primary 0%, $primary 50%, #fff 50%, #fff 100%);
  border-radius: .4rem;
  height: 4px;
  width: 100%;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 2rem;
  width: 2rem;
  border-radius: 1rem;
  background: $primary;
  cursor: pointer;
  margin-top: -0.2rem;
}