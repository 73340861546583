@import "app/styles/assets/scss/colors";
@import "app/styles/assets/scss/variables";


.main_wrapper {
  display: grid;
  grid-gap: 2rem;
  width: calc(100vw - 3.2rem);
}

.date_picker_wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: .8rem;

  > * {
    flex: 1 1 0;
  }
}

.wrapperOverlay {
  position: relative;

  border: 1px solid $grayLighter;
  padding: 1rem 1.2rem;
  border-radius: 1.2rem;

  &::before {
    content: "";
    position: absolute;
    top: calc(50%);
    left: 0;
    right: 0;
    height: 2.2rem;
    margin: 0 1.2rem;
    border-radius: .8rem;
    background-color: $grayLightrest1;
    transform: translateY(-50%);
    z-index: 1;
  }
}

.item_wrapper {
  position: relative;
  height: 8.2rem;
  overflow-y: scroll;
  z-index: 2;

  flex-wrap: nowrap;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

.centerItem {
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-weight: 400;
  color: $black;
}

.adjacentItem {
  color: #ADADC2;
  font-size: 1.2rem;
  line-height: 2.2rem; // important
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.2rem;
  margin-bottom: .8rem; //important
  z-index: 3;
  position: relative;
}