@import "src/app/styles/assets/scss/colors";

.wrapper {
  width: 100%;
  touch-action: none;
  position: fixed;
  background: $black;
  backdrop-filter: blur(16px);
  bottom: 0;
  padding-bottom: var(--saib);
  z-index: 999;
  transition: all .3s;

  .description {
    overflow: hidden;
    color: $white;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.9rem;
    padding: 0 1.6rem;
    transition: max-height .4s cubic-bezier(0.4, 0, 0.2, 1);
    word-break: break-word;

    &.open {
      overflow-y: auto;
    }
  }
}