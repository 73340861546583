@import "app/styles/assets/scss/colors";

.holder {
  position: relative;
  width: 4.4rem;

  .wrapper {
    position: absolute;
    width: 4.4rem;
    height: 2.4rem;
    background-color: $grayLighter;
    border-radius: 4rem;
    transition: background-color .2s cubic-bezier(0.6, 0, 0.74, 0.05);
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 0 0 2.4rem;

    &.active {
      background-color: $success;
    }

    &.disabled {
      pointer-events: none;
      opacity: .4;
    }

    .indicator {
      position: absolute;
      width: 2rem;
      height: 2rem;
      border-radius: 100%;
      background-color: white;
      left: .2rem;
      top: 50%;
      transform: translateY(-50%);
      transition: transform .2s cubic-bezier(0.6, 0, 0.74, 0.05);

      &.active {
        transform: translate(2rem, -50%);
      }
    }
  }
}