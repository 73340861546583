@import "app/styles/assets/scss/colors";

.cropper-view-box {
  outline: .2rem solid $primary !important;
  border-radius: 1.2rem !important;
}

.cropper-center {
  display: none !important;
}

.cropper-face {
  border-radius: 1.2rem !important;
}

.point-nw {
  width: 3rem !important;
  height: .4rem !important;
  top: -.4rem !important;
  left: -.4rem !important;
  background-color: $primary !important;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 3rem;
    background-color: $primary;
    width: .4rem;
  }
}

.point-ne {
  width: .4rem !important;
  height: 3rem !important;
  top: -.4rem !important;
  right: -.4rem !important;
  background-color: $primary !important;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: .4rem;
    background-color: $primary;
    width: 3rem;
  }
}

.point-sw {
  width: .4rem !important;
  height: 3rem !important;
  left: -.4rem !important;
  bottom: -.4rem !important;
  background-color: $primary !important;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: .4rem;
    background-color: $primary;
    width: 3rem;
  }
}

.point-se {
  width: .4rem !important;
  height: 3rem !important;
  right: -.4rem !important;
  bottom: -.4rem !important;
  background-color: $primary !important;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    height: .4rem;
    background-color: $primary;
    width: 3rem;
  }
}

.point-n {
  display: none !important;
}

.point-s {
  display: none !important;
}


.point-w {
  display: none !important;
}

.point-e {
  display: none !important;
}

.cropper-dashed {
  display: none !important;
}

.cropper-modal {
  background-color: $black !important;
  opacity: .5 !important;
}