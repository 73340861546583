@import "src/app/styles/assets/scss/colors";
@import "src/app/styles/assets/scss/variables";

.wrapper {
  display: flex;
  gap: .4rem;
}

.star {
  font-family: $iconFont2;
  font-size: 1.4rem;
  line-height: 1;
  color: $grayLighter;
}

.star_active {
  @extend .star;

  color: $warning;
}