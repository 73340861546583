@import "src/app/styles/assets/scss/colors";
@import "src/app/styles/assets/scss/variables";

.wrapper {
  width: 100%;
  display: grid;
  flex-direction: column;
  grid-gap: .4rem;
}

.input_wrapper {
  display: flex;
  align-items: center;
  background-color: $grayLightrest1;
  border-radius: $borderRadius;
  padding: 1rem 1.2rem;

  &.error {
    background-color: $errorLightrest1;
  }

  .clear_button {
    font-size: 2rem;
    line-height: 1;
    margin-left: 1.2rem;
  }
}

.input {
  width: 100%;
  outline: none;
  border: none;
  color: $black;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.2rem;
  background-color: transparent;
  padding: 0 !important;
  margin: 0 !important;
  display: flex;
  align-items: center;

  &.error {
    color: $error;

    &::placeholder {
      color: $errorLight !important;
    }
  }

  &.disabled {
    pointer-events: none;
    color: $grayDark;

    &::placeholder {
      color: $grayDark;
    }
  }

  &::placeholder {
    color: $gray;
    font-size: 1.6rem;
    line-height: 2.2rem;
    font-weight: 400;
  }
}

.subtext {
  display: flex;
  align-items: center;
  gap: .4rem;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.7rem;
  color: $grayDark;

  &.error {
    color: $error;
  }
}

.label {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.7rem;
  color: $black;
}

.label_icon {
  font-size: 1.2rem;
  line-height: 1;
  padding: .25rem 0;
  font-family: $iconFont2;
  color: $gray;
  align-self: flex-start;
  margin-right: .4rem;

  &.label_asterix {
    color: $error;
  }
}

.subtext_icon {
  align-self: flex-start;
  font-family: $iconFont2;
  font-size: 1.2rem;
  line-height: 1;
  padding: .25rem 0;
}

.icon {
  font-family: $iconFont2;
  font-size: 2rem;
  line-height: 1;
  color: $gray;

  &.error {
    color: $error;
  }
}