.loading {
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    animation: 3s linear infinite rotate;
    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }
  }
}