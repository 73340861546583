@import "src/app/styles/assets/scss/colors";

.wrapper {
  display: grid;
  grid-gap: .4rem;

  &.has_space {
    padding-bottom: 2.4rem;
  }

  .textarea_holder {
    position: relative;
    display: grid;
    grid-gap: .8rem;

    .label {
      color: $black;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;
      letter-spacing: -0.14px;
    }

    .required {
      color: $error;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;
      letter-spacing: -0.14px;
    }

    .textarea {
      width: calc(100% - 2.4rem);
      min-height: 8.8rem;
      padding: 1rem 1.2rem;
      outline: none;
      border: none;
      overflow-y: auto;
      background-color: $grayLightrest1;
      border-radius: 1.2rem;
      resize: none;

      color: $black;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 2.2rem;

      &.error {
        background-color: $errorLightrest1;
        color: $error;
      }

      &.isNew {
        width: auto;
        border-radius: 1.6rem;
        padding: 1.6rem;
        color: $black;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        letter-spacing: -0.16px;
      }

      &::-webkit-resizer {
        display: none;
      }

      &::placeholder {
        color: $gray;
      }
    }

    .text_counter {
      position: absolute;
      right: 0;
      bottom: 0;
      background-color: $grayLightrest2;
      border-radius: 2.8rem;
      padding: .2rem .6rem;

      color: $grayDark;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.4rem;

      &.error {
        background-color: $errorLightrest2;
        color: $error;
      }
    }
  }

  .hint_wrapper {
    display: grid;
    grid-gap: .4rem;
    grid-template-columns: 1.2rem 1fr;

    &.isNew {
      display: flex;
    }

    .hint_icon {
      color: $gray;
      min-width: 1.2rem !important;
      min-height: 1.2rem !important;
      font-size: 1.2rem;
      line-height: 1;
      align-self: flex-start;
      height: 1.7rem;
    }

    .hint_text {
      color: $grayDark;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.7rem;

      &.isNew {
        color: $grayDark;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
      }
    }
  }

  .error_message {
    color: $error;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.7rem;
  }
}