@use "sass:math";
@import './variables';

.mga-a {
  margin: auto;
}

.mgx-a {
  margin-left: auto;
  margin-right: auto;
}

.mgl-a {
  margin-left: auto;
}

.mgr-a {
  margin-right: auto;
}

@for $i from 0 through $spacingCount {
  .mgx-#{$i} {
    margin-left: $i * $spacingStep !important;
    margin-right: $i * $spacingStep !important;
  }
  .mgy-#{$i} {
    margin-top: $i * $spacingStep !important;
    margin-bottom: $i * $spacingStep !important;
  }
  .mga-#{$i} {
    margin: $i * $spacingStep !important;
  }
  .mgt-#{$i} {
    margin-top: $i * $spacingStep !important;
  }
  .mgr-#{$i} {
    margin-right: $i * $spacingStep !important;
  }
  .mgb-#{$i} {
    margin-bottom: $i * $spacingStep !important;
  }
  .mgl-#{$i} {
    margin-left: $i * $spacingStep !important;
  }
  .pdx-#{$i} {
    padding-left: $i * $spacingStep !important;
    padding-right: $i * $spacingStep !important;
  }
  .pdy-#{$i} {
    padding-top: $i * $spacingStep !important;
    padding-bottom: $i * $spacingStep !important;
  }
  .pda-#{$i} {
    padding: $i * $spacingStep !important;
  }
  .pdt-#{$i} {
    padding-top: $i * $spacingStep !important;
  }
  .pdr-#{$i} {
    padding-right: $i * $spacingStep !important;
  }
  .pdb-#{$i} {
    padding-bottom: $i * $spacingStep !important;
  }
  .pdl-#{$i} {
    padding-left: $i * $spacingStep !important;
  }

}

.pda-1-2 {
  padding:  math.div($spacingStep, 2) !important;
}

.pdr-1-2 {
  padding-right: math.div($spacingStep, 2) !important;
}

.pdl-1-2 {
  padding-left: math.div($spacingStep, 2) !important;
}

.pdt-1-2 {
  padding-top: math.div($spacingStep, 2) !important;
}

.pdb-1-2 {
  padding-bottom: math.div($spacingStep, 2) !important;
}

.pdy-1-2 {
  padding-top: math.div($spacingStep, 2) !important;
  padding-bottom: math.div($spacingStep, 2) !important;
}

.pdx-1-2 {
  padding-left: math.div($spacingStep, 2) !important;
  padding-right: math.div($spacingStep, 2) !important;
}

.mgr-1-2 {
  margin-right: math.div($spacingStep, 2) !important;
}

.mgl-1-2 {
  margin-left: math.div($spacingStep, 2) !important;
}

.mgb-1-2 {
  margin-bottom: math.div($spacingStep, 2) !important;
}

.mgt-1-2 {
  margin-top: math.div($spacingStep, 2) !important;
}

.mgy-1-2 {
  margin-top: math.div($spacingStep, 2) !important;
  margin-bottom: math.div($spacingStep, 2) !important;
}

.mgt--1-2 {
  margin-top: math.div(-$spacingStep, 2) !important;
}

.mgy--1-2 {
  margin-top: math.div(-$spacingStep, 2) !important;
  margin-bottom: math.div(-$spacingStep, 2) !important;
}

