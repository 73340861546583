@import "src/app/styles/assets/scss/colors";
@import "src/app/styles/assets/scss/variables";


.checkbox {
  position: relative;
  width: 2.4rem;
  height: 2.4rem;
  display: inline-block;

  &.small {
    width: 1.6rem;
    height: 1.6rem;
    display: flex;
  }


  input[type="checkbox"] {
    font-family: $iconFont2;
    -webkit-appearance: none;
    appearance: none;
    background-color: $white;
    margin: 0;


    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;

    &.is_new {
      font-family: $iconFont2;
    }
  }


  .checkbox_custom {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-family: $iconFont2;
    line-height: 1;
    z-index: 1;

    &::before {
      content: 'checkbox_unchecked';
      font-size: 2.4rem;
      line-height: 1;
      color: $gray;
    }

    &.is_new::before {
      content: 'checkbox_unchecked';
      font-size: 2rem;
      line-height: 1;
      color: $gray;
    }

    &.small::before {
      content: 'checkbox_unchecked';
      font-size: 1.6rem;
      line-height: 1;
      color: $gray;
    }

    &.is_new {
      font-family: $iconFont2;
    }
  }

  &.is_new {
    width: 2rem;
    height: 2rem;
    input[type="checkbox"]:checked + .checkbox_custom::before {
      content: 'checkbox_checked';
      color: $black;
    }

    input[type="checkbox"]:checked:disabled + .checkbox_custom::before {
      content: 'checkbox_checked';
      color: $grayLighter;
    }

    input[type="checkbox"]:disabled + .checkbox_custom::before {
      content: 'checkbox_unchecked';
      color: $grayLighter;
    }
  }

  input[type="checkbox"]:checked + .checkbox_custom::before {
    content: 'checkbox_checked';
    color: $primary;
  }

  input[type="checkbox"]:checked::before {
    content: 'checkbox_checked';
    color: $primary;
  }

  input[type="checkbox"]:focus {
    outline: none;
  }
}