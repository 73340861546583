@import "src/app/styles/assets/scss/colors";
@import "src/app/styles/assets/scss/variables";

.holder {

  &.has_space {
    margin-bottom: 2.4rem;
  }

  .wrapper {
    display: flex;
    width: 100%;
    overflow: hidden;
    align-items: center;

    &.disabled {
      opacity: .4;
    }

    .icon_left {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $iconFont2;
      font-size: 2rem;
      line-height: 1;
      color: $gray;
    }

    .icon_right {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: auto;
      min-width: 2rem;
    }

    .content {
      flex: 1 1 0;
      display: grid;
      grid-gap: .4rem;
      align-items: center;

      .title {
        color: $black;
        font-size: 1.6rem;
        font-weight: 500;
        letter-spacing: -0.16px;
        line-height: 2.1rem;
      }

      .subtitle {
        color: $grayDark;
        font-size: 14px;
        font-weight: 400;
        line-height: 130%;
        letter-spacing: -0.14px;

        &.error {
          color: $error;
        }
      }

      .link {
        color: $primary;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 130%;
        letter-spacing: -0.14px;
        height: 1.8rem;

        &.has_url {
          text-decoration-line: underline;
        }
      }
    }
  }

  .children {
    overflow: hidden;
    transition: max-height .4s cubic-bezier(0.4, 0, 0.2, 1);
  }
}