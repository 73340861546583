@import "app/styles/assets/scss/colors";

.layout {
  padding: 0 1.6rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: .4rem;

  .item {
    height: .2rem;
    background-color: $grayLighter;
    border-radius: 8px;
    transition: background-color .5s ease-out;

    &.active {
      background-color: $successDark;
    }
  }
}