@import "app/styles/assets/scss/colors";
@import "app/styles/assets/scss/variables";

.children_wrapper {
  position: relative;
}

.wrapper {
  background-color: $black;
  color: $white;
  border-radius: $borderRadius;
  padding: 1.2rem;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;

  .text {
    color: $white;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.9rem;
  }
}

.icon {
  font-family: $iconFont2;
  font-size: 1.6rem;
  line-height: 1;
  margin-right: .8rem;
}
