@import "src/app/styles/assets/scss/colors";

.wrapper {

}

.hidden_input {
  position: fixed;
  z-index: -1;
  bottom: 0;
  flex: 1 1 0;
  border: transparent;
  background-color: transparent;
  caret-color: transparent;
  color: transparent;
  font-size: 1.6rem;
}

.inputs_holder {
  display: flex;
  justify-content: center;
  align-items: center;

  > *:not(:last-child) {
    margin-right: .8rem;
  }

  input {
    text-align: center;
  }
}

.error {
  margin-top: .4rem;
  height: 1.7rem;

  color: $error;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.7rem;
}