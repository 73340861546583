@import "src/app/styles/assets/scss/colors";
@import "src/app/styles/assets/scss/variables";


.wrapper {
  align-items: center;
  width: 100%;
  display: flex;

  &.has_space {
    padding-bottom: 2.4rem;
  }

  &.rounded {
    justify-content: center;
  }

  .button_rounded {
    position: relative;
    padding: .8rem 1.6rem;
    border-radius: 10rem;
    outline: none;
    border: none;
    transition: color, background-color .5s ease-out;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.6rem;

    &:disabled {
      background-color: $grayLightrest1 !important;
      color: $gray !important;
      pointer-events: none !important;
      border-color: transparent !important;
    }

    &.default {
      color: $white;

      &.black {
        background-color: $black;

        &:active {
          background-color: $grayDarker;
        }
      }

      &.error {
        background-color: $error;

        &:active {
          background-color: $errorDark;
        }
      }

      &.success {
        background-color: $successDark;

        &:active {
          background-color: $successDarker;
        }
      }

      &.gray {
        background-color: $grayLightrest1;
        color: $black;

        &:active {
          background-color: $grayLighter;
        }
      }
    }

    &.wrapped {
      background-color: $white;

      &.black {
        padding: .7rem 1.5rem;
        border: 1px solid $black;
        color: $black;

        &:active {
          background-color: $black;
          color: $white;
        }
      }

      &.error {
        padding: .7rem 1.5rem;
        border: 1px solid $error;
        color: $error;

        &:active {
          background-color: $error;
          color: $white;
        }
      }

      &.success {
        padding: .7rem 1.5rem;
        border: 1px solid $success;
        color: $success;

        &:active {
          background-color: $success;
          color: $white;
        }
      }

      &.gray {
        padding: .7rem 1.5rem;
        border: 1px solid $grayLighter;
        color: $black;

        &:active {
          background-color: $grayLighter;
          color: $black;
        }
      }
    }

    &.is_loading {
      height: 3.2rem;
      width: 14.4rem;
      pointer-events: none;
    }
  }

  .additional_button {
    padding: 1.5rem 1.9rem;
    background-color: $white;
    border: 1px solid $grayLighter;
    border-radius: 50px;
    outline: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: color, background-color .5s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: .8rem;

    &:active {
      background-color: $black;

      > div {
        color: $white;
      }
    }

    .additional_icon {
      width: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $iconFont2;
      color: $black;
      font-size: 2rem;
      line-height: 1;
      height: 2.1rem;
    }

    .additional_text {
      color: $black;
      text-align: center;
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 130%;
      letter-spacing: -0.16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 2.1rem;
    }
  }

  .button {
    position: relative;
    width: 100%;
    padding: 1.6rem 2rem;
    border-radius: 50px;
    outline: none;
    border: none;
    transition: color, background-color .5s ease-out;

    &:disabled {
      background-color: $grayLightrest1 !important;
      color: $gray !important;
      pointer-events: none !important;
    }

    &.is_loading {
      pointer-events: none;
    }

    &.default {
      color: $white;

      &.black {
        background-color: $black;

        &:active {
          background-color: $grayDarker;
        }
      }

      &.error {
        background-color: $error;

        &:active {
          background-color: $errorDark;
        }
      }

      &.success {
        background-color: $successDark;

        &:active {
          background-color: $successDarker;
        }
      }

      &.gray {
        background-color: $grayLightrest1;
        color: $black;

        &:active {
          background-color: $grayLighter;
        }
      }
    }

    &.wrapped {
      background-color: $white;

      &.black {
        padding: 1.5rem 1.9rem;
        border: 1px solid $black;
        color: $black;

        &:active {
          background-color: $black;
          color: $white;
        }
      }

      &.error {
        padding: 1.5rem 1.9rem;
        border: 1px solid $error;
        color: $error;

        &:active {
          background-color: $error;
          color: $white;
        }
      }

      &.success {
        padding: 1.5rem 1.9rem;
        border: 1px solid $success;
        color: $success;

        &:active {
          background-color: $success;
          color: $white;
        }
      }

      &.gray {
        padding: 1.5rem 1.9rem;
        border: 1px solid $grayLighter;
        color: $black;

        &:active {
          background-color: $grayLighter;
          color: $black;
        }
      }
    }

    .children_holder {
      display: grid;
      grid-template-columns: 2rem auto 2rem;
      grid-gap: 1.2rem;
      justify-content: center;
      align-items: center;
      width: 100%;
      overflow: hidden;

      &.is_loading {
        opacity: 0;
      }

      .text_wrapper {
        display: flex;
        flex-direction: column;
        height: 2.1rem;
        justify-content: center;
        overflow: hidden;

        &.second_line {
          height: 3.6rem;
        }

        span {
          text-align: center;
          font-size: 1.6rem;
          font-weight: 500;
          line-height: 130%;
          letter-spacing: -0.16px;
          height: 2.1rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        span:nth-child(2) {
          font-size: 1rem;
          font-weight: 400;
          line-height: 130%;
          text-align: center;
          letter-spacing: -0.16px;
          height: 1.3rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}