@font-face {
  font-family: 'TildaFont';
  font-style: normal;
  font-weight: 300;
  src: url('TildaSans-Light.woff2') format("woff2"),
  url('TildaSans-Light.woff') format("woff"),
  url('TildaSans-Light.ttf') format("truetype");
}

@font-face {
  font-family: 'TildaFont';
  font-style: normal;
  font-weight: 400;
  src: url('TildaSans-Regular.woff2') format("woff2"),
  url('TildaSans-Regular.woff') format("woff"),
  url('TildaSans-Regular.ttf') format("truetype");
}

@font-face {
  font-family: 'TildaFont';
  font-style: normal;
  font-weight: 500;
  src: url('TildaSans-Medium.woff2') format("woff2"),
  url('TildaSans-Medium.woff') format("woff"),
  url('TildaSans-Medium.ttf') format("truetype");
}

@font-face {
  font-family: 'TildaFont';
  font-style: normal;
  font-weight: 600;
  src: url('TildaSans-Semibold.woff2') format("woff2"),
  url('TildaSans-Semibold.woff') format("woff"),
  url('TildaSans-Semibold.ttf') format("truetype");
}

@font-face {
  font-family: 'TildaFont';
  font-style: normal;
  font-weight: 700;
  src: url('TildaSans-Bold.woff2') format("woff2"),
  url('TildaSans-Bold.woff') format("woff"),
  url('TildaSans-Bold.ttf') format("truetype");
}

@font-face {
  font-family: 'TildaFont';
  font-style: normal;
  font-weight: 800;
  src: url('TildaSans-ExtraBold.woff2') format("woff2"),
  url('TildaSans-ExtraBold.woff') format("woff"),
  url('TildaSans-ExtraBold.ttf') format("truetype");
}

@font-face {
  font-family: 'TildaFont';
  font-style: normal;
  font-weight: 900;
  src: url('TildaSans-Black.woff2') format("woff2"),
  url('TildaSans-Black.woff') format("woff"),
  url('TildaSans-Black.ttf') format("truetype");
}

$fontTilda: TildaFont;
