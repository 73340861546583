@import "src/app/styles/assets/scss/colors";

.video-player {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: auto;
}

.controls {
  position: fixed;
  bottom: var(--saib);
  display: grid;
  grid-gap: .8rem;
  width: 100vw;
  padding: 1.6rem 0 .8rem 0;
  background-color: $black;
  z-index: 9999;

  .control_icon {
    display: flex;
    justify-content: center;
  }

  .progress_holder {
    display: grid;
    grid-gap: .4rem;
    padding: 0 1.6rem;

    .duration_holder {
      display: flex;
      justify-content: space-between;
      align-items: center;

      color: $white;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.7rem;
    }

    .progress_bar {
      width: 100%;
      background-color: $gray;
      height: .2rem;

      .progress {
        background-color: $white;
        width: 0;
        height: .2rem;
        position: relative;

        &::before {
          content: "";
          width: .8rem;
          height: .8rem;
          background-color: $white;
          position: absolute;
          right: -.8rem;
          bottom: -.3rem;
          border-radius: 1.6rem;
        }
      }
    }
  }
}