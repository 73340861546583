@import "src/app/styles/assets/scss/colors";

.preload_wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;

  .video_overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $white;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .bubble {
      position: absolute;
      content: "";
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      background-color: $black;
      opacity: .6;
      z-index: 2;
    }

    span {
      z-index: 3;
    }
  }

  .error_chat {
    width: 7.2rem;
    height: 7.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
    border-radius: 1.2rem;

    .error_message {
      color: $gray;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: normal;
      text-align: center;
    }
  }

  .error {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    padding: .8rem;
    display: grid;
    grid-gap: .8rem;

    border-radius: 1.2rem;
    background-color: $grayLightrest2;
    border: 1px solid $grayLightrest1;

    .error_icon {
      font-size: 2.4rem;
      color: $grayDark;
      align-self: flex-end;
      justify-self: center;

      &.small {
        font-size: 2rem;
      }
    }

    .error_text {
      color: $grayDark;
      text-align: center;
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 1.9rem;

      &.small {
        font-size: 1rem;
        line-height: 1.4rem;
      }
    }
  }

  .preview_image {
    cursor: pointer;
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 1.2rem;
    background-color: $grayLighter;
    box-sizing: border-box;
    border: 1px solid $grayLighter;
    overflow: hidden;

    .image {
      width: 100%;
      border-radius: 1.2rem;
      object-fit: cover;

    }

    .video {
      height: 14rem;
      text-align: center;
      border-radius: 1.2rem;
      margin: 0 auto;
    }

    .chat_image {
      width: 7.2rem;
      height: 7.2rem;
      border-radius: 1.2rem;
    }

    .loaded {
      //display: none;
      background-color: $grayLighter;
    }
  }
}

.last_item_count {
  position: absolute;
  top: 0;
  left: 0;
  height: 6rem;
  width: 6rem;
  border-radius: .4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.6;
  background-color: $black;
  z-index: 3;

  color: $white;
  font-size: 1.6rem;
  font-weight: 800;
  line-height: 2.2rem;
}