@import "src/app/styles/assets/scss/colors";


.inApp_subtext {
  display: grid;
  color: $grayDark;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.9rem;
  letter-spacing: -0.14px;

  span:first-child {
    margin-bottom: 1.9rem;
  }
}