@import "src/app/styles/assets/scss/colors";
@import "src/app/styles/assets/scss/variables";


.radio_wrapper {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  input[type="radio"]:focus {
    outline: none;
  }
}

.radio_input_wrapper {
  position: relative;
  width: 2rem;
  height: 2rem;
  display: inline-block;

  input[type="radio"] {
    cursor: pointer;
    font-family: $iconFont2;
    -webkit-appearance: none;
    appearance: none;
    background-color: $white;
    margin: 0;

    &:disabled {
      &::before {
        color: $grayLight !important;
      }
    }

    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;

    &.is_new {
      font-family: $iconFont2 !important;
    }
  }

  .radio_custom {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-family: $iconFont2;
    line-height: 1;
    z-index: 1;

    &::before {
      content: 'radiobutton_unchecked';
      font-size: 2rem;
      line-height: 1;
      color: $gray;
    }

    &.is_new::before {
      font-family: $iconFont2 !important;
      font-size: 2rem;
      line-height: 1;
      color: $gray;
    }
  }

  input[type="radio"]:checked + .radio_custom::before {
    content: 'radiobutton_checked';
    color: $primary;
  }

  &.is_new {
    input[type="radio"]:checked + .radio_custom::before {
      content: 'radiobutton_checked';
      color: $black;
    }

    input[type="radio"]:checked:disabled + .radio_custom::before {
      content: 'radiobutton_checked';
      color: $grayLighter;
    }

    input[type="radio"]:disabled + .radio_custom::before {
      content: 'radiobutton_unchecked';
      color: $grayLighter;
    }
  }
}

.radio_label {
  display: flex;
  align-items: center;
  color: $black;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.2rem;

  &.is_new {
    color: $black;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: -0.16px;
    height: 2.1rem;
  }
}

.radio_label_disabled {
  @extend .radio_label;

  color: $grayLight !important;
}