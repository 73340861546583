@import "src/app/styles/assets/scss/colors";
@import "src/app/styles/assets/scss/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin: 0 -1.6rem 2.4rem -1.6rem;

  .header {
    color: $black;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.14px;
    height: 1.8rem;
    padding-left: 1.6rem;
  }

  .tabs_holder {
    margin: 1.2rem 0 0 0;
    display: flex;
    justify-content: flex-start;

    flex-wrap: nowrap;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    list-style: none;
    padding: 0 1.6rem 0 0;

    li:first-child, {
      margin-left: 1.6rem;
    }

    li:not(:last-child) {
      margin-right: .8rem;
    }

    &::-webkit-scrollbar {
      display: none;
      height: 0;
      width: 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    &.full_position {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    .tab {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem 1.4rem;
      border-radius: 30px;
      background-color: $grayLightrest1;
      transition: color, background-color .5s ease-out;

      &.active {
        background-color: $black;

        span {
          color: $white !important;
        }
      }

      .tab_icon {
        width: 1.6rem;
        height: 1.6rem;
        font-family: $iconFont2;
        font-size: 1.6rem;
        line-height: 1;
        color: $grayDark;
      }

      .tab_text_holder {
        display: grid;
        justify-content: center;
        align-items: center;
      }

      .tab_text {
        color: $black;
        text-align: center;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.6rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .additional_item {
      display: grid;
      justify-content: center;
      align-items: center;
      padding: .8rem 1.6rem;
      background-color: $grayLightrest1;
      border-radius: 12px;

      &.active {
        background-color: $black;

        span {
          color: $white !important;
        }
      }

      .title {
        display: flex;
        color: $black;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.6rem;
        text-align: center;
        white-space: nowrap;
      }

      .subtext {
        text-align: center;
        color: $black;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.1rem;
        letter-spacing: -0.16px;
      }
    }
  }
}