.content {
  display: grid;
  grid-gap: 2.4rem;
  padding-bottom: 2.4rem;
}

.image {
  height: 16rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}