$borderRadius: 1.2rem;
$spacingStep: 4px;
$spacingCount: 20;
$fontTilda: "TildaFont";
$iconFont2: "Naimi-icons-2";

:export {
  borderRadius: $borderRadius;
  spacingStep: $spacingStep;
  spacingCount: $spacingCount;
  tildaFont: $fontTilda;
  iconFont2: $iconFont2;
}