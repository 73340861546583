@import "src/app/styles/assets/scss/colors";

.text_block {
  display: grid;
  grid-gap: 1.2rem;

  &.small_gap {
    grid-gap: .4rem;
  }

  .text_block_title {
    color: $black;
    font-size: 2rem;
    font-weight: 800;
    line-height: 2.8rem;
  }

  .text_block_description {
    color: $grayDark;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.9rem;
  }
}