$gray: #ADADC2;
$primary: #0A84FF;
$success: #32D74B;
$warning: #FFD60A;
$error: #FF1507;
$accent1: #840AFE;
$accent2: #FE0A84;
$accent3: #FE840A;
$black: #2B2B2B;
$white: #FFFFFF;
$lightGray: #D6D6E1;

$grayDark: #828291;
$grayDarker: #575761;
$grayLight: #D6D6E1;
$grayLighter: #EAEAEF;
$grayLightrest1: #F7F7F9;
$grayLightrest2: #FBFBFC;

$primaryDark: #0763BF;
$primaryDarker: #054280;
$primaryLight: #85C2FF;
$primaryLighter: #C1E0FF;
$primaryLightrest1: #E7F3FF;
$primaryLightrest2: #F2F9FF;

$successDark: #25A138;
$successDarker: #196C26;
$successLight: #99EBA5;
$successLighter: #CBF5D2;
$successLightrest1: #EBFBED;
$successLightrest2: #F4FDF6;

$warningDark: #BFA007;
$warningDarker: #806B05;
$warningLight: #FFEB85;
$warningLighter: #FFF4C1;
$warningLightrest1: #FFFBE7;
$warningLightrest2: #FFFDF2;

$errorDark: #BF1005;
$errorDarker: #800B04;
$errorLight: #FF8A83;
$errorLighter: #FFC4C1;
$errorLightrest1: #FFE8E7;
$errorLightrest2: #FFF3F2;

$accent1Dark: #6307BE;
$accent1Darker: #42057F;
$accent1Light: #C285FF;
$accent1Lighter: #E0C1FE;
$accent1Lightrest1: #F3E7FF;
$accent1Lightrest2: #F9F2FF;

$accent2Dark: #BE0763;
$accent2Darker: #7F0542;
$accent2Light: #FF85C2;
$accent2Lighter: #FEC1E0;
$accent2Lightrest1: #FFE7F3;
$accent2Lightrest2: #FFF2F9;

$accent3Dark: #BE6307;
$accent3Darker: #7F4205;
$accent3Light: #FFC285;
$accent3Lighter: #FEE0C1;
$accent3Lightrest1: #FFF3E7;
$accent3Lightrest2: #FFF9F2;

:export {
  gray: $gray;
  primary: $primary;
  success: $success;
  warning: $warning;
  error: $error;
  accent1: $accent1;
  accent2: $accent2;
  accent3: $accent3;
  black: $black;
  white: $white;
  lightGray: $lightGray;

  grayDark: $grayDark;
  grayDarker: $grayDarker;
  grayLight: $grayLight;
  grayLighter: $grayLighter;
  grayLightrest1: $grayLightrest1;
  grayLightrest2: $grayLightrest2;

  primaryDark: $primaryDark;
  primaryDarker: $primaryDarker;
  primaryLight: $primaryLight;
  primaryLighter: $primaryLighter;
  primaryLightrest1: $primaryLightrest1;
  primaryLightrest2: $primaryLightrest2;

  successDark: $successDark;
  successDarker: $successDarker;
  successLight: $successLight;
  successLighter: $successLighter;
  successLightrest1: $successLightrest1;
  successLightrest2: $successLightrest2;

  warningDark: $warningDark;
  warningDarker: $warningDarker;
  warningLight: $warningLight;
  warningLighter: $warningLighter;
  warningLightrest1: $warningLightrest1;
  warningLightrest2: $warningLightrest2;

  errorDark: $errorDark;
  errorDarker: $errorDarker;
  errorLight: $errorLight;
  errorLighter: $errorLighter;
  errorLightrest1: $errorLightrest1;
  errorLightrest2: $errorLightrest2;

  accent1Dark: $accent1Dark;
  accent1Darker: $accent1Darker;
  accent1Light: $accent1Light;
  accent1Lighter: $accent1Lighter;
  accent1Lightrest1: $accent1Lightrest1;
  accent1Lightrest2: $accent1Lightrest2;

  accent2Dark: $accent2Dark;
  accent2Darker: $accent2Darker;
  accent2Light: $accent2Light;
  accent2Lighter: $accent2Lighter;
  accent2Lightrest1: $accent2Lightrest1;
  accent2Lightrest2: $accent2Lightrest2;

  accent3Dark: $accent3Dark;
  accent3Darker: $accent3Darker;
  accent3Light: $accent3Light;
  accent3Lighter: $accent3Lighter;
  accent3Lightrest1: $accent3Lightrest1;
  accent3Lightrest2: $accent3Lightrest2;
}
