@import "src/app/styles/assets/scss/colors";

.description {
  color: $grayDarker;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.9rem;
  letter-spacing: -0.14px;

  .sum {
    color: $grayDarker;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.9rem;
    letter-spacing: -0.14px;
  }
}