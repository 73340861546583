@import "src/app/styles/assets/scss/colors";

.loading_wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading {
  width: 2.4rem;
  height: 2.4rem;

  &.isRounded {
    width: 1.6rem;
    height: 1.6rem;
  }

  &.default {
    &.black, &.error, &.success {
      svg {
        g {
          g {
            g {
              path {
                stroke: $white;
              }
            }
          }
        }
      }
    }

    &.gray {
      svg {
        g {
          g {
            g {
              path {
                stroke: $black;
              }
            }
          }
        }
      }
    }
  }

  &.wrapped {
    &.black, &.gray {
      svg {
        g {
          g {
            g {
              path {
                stroke: $black;
              }
            }
          }
        }
      }
    }

    &.error {
      svg {
        g {
          g {
            g {
              path {
                stroke: $error;
              }
            }
          }
        }
      }
    }

    &.success {
      svg {
        g {
          g {
            g {
              path {
                stroke: $success;
              }
            }
          }
        }
      }
    }
  }

  animation: 3s linear infinite rotate;
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

}