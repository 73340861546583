.skeleton_prices {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.4rem;

  .skeleton_prices_left {
    display: grid;
    grid-gap: .4rem;
  }
}