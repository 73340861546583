@import "src/app/styles/assets/scss/colors";
@import "src/app/styles/assets/scss/variables";

.toggle_button {
  position: absolute;
  top: 1.8rem;
  right: 1.6rem;
  font-size: 2rem !important;
  font-weight: 500;
  line-height: 1;
  transition: transform .2s linear;
  transform: rotate(0deg);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $grayDarker !important;
}

.toggle_button_open {
  transform: rotate(45deg);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.accordion {
  box-sizing: border-box;
  width: 100%;
  border-radius: 1.2rem;
  border: 1px solid $grayLighter;
  position: relative;
  padding: 1.6rem 3.6rem 1.6rem 1.6rem;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.2rem;
  color: $black;
  transition: background-color .5s ease-out;

  &.open {
    background: $grayLightrest1;
    border: 1px solid $grayLightrest1;
  }

  .content {
    overflow: hidden;
    transition: max-height .4s cubic-bezier(0.4, 0, 0.2, 1);

    color: $black;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
  }
}