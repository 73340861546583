@import "src/app/styles/assets/scss/colors";
@import "src/app/styles/assets/scss/variables";

.photo_wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &.has_review {
    height: calc(100% - 6.7rem);
  }

  & img, video {
    max-width: 100%;
    max-height: 100%;
  }

  & video {
    width: 100%;
  }

  .loader {
    color: $white;
  }

  .error {
    width: 30rem;
    height: 50rem;
    padding: .8rem;
    display: flex;
    flex-direction: column;
    text-align: center;

    border-radius: 1.2rem;
    justify-content: center;
    align-items: center;
    background-color: $black;
    color: $white;

    .error_text {
      color: $white;
      text-align: center;
      font-size: 2rem;
      font-weight: 600;
      line-height: 2.8rem;
    }
  }
}

.info_block {
  display: grid;
  padding: 0 1.6rem;

  .review_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1.2rem 0;

    .review_text_holder {
      display: grid;
      grid-gap: .4rem;
      color: $white;

      .review_price {
        font-size: 1.6rem;
        font-weight: 800;
        line-height: 2.2rem;
      }

      .review_date {
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.7rem;
      }
    }

    .stars_holder {
      display: grid;
      grid-template-columns: repeat(5, 1.6rem);
      grid-gap: .4rem;
      color: white;

      &.filled {
        color: $warning;
      }
    }
  }
}