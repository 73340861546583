@import "src/app/styles/assets/scss/colors";

.wrapper {
  width: calc(100% - 3.2rem);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 1.6rem;

  display: grid;
  grid-gap: 1.2rem;

  .icon {
    display: inline-flex;
    font-size: 4rem;
    line-height: 1;
    color: $gray;
    justify-self: center;
  }

  .text {
    display: grid;
    grid-gap: .4rem;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.9rem;
    color: $grayDark;

    span:nth-child(1) {
      color: $black;
      text-align: center;
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 2.2rem;
    }
  }
}