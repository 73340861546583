@import "src/app/styles/assets/scss/colors";

.error {
  position: absolute;
  z-index: 99999;
  top: calc(var(--sait) + .8rem);
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100vw - 8rem);
  background-color: $error;
  border-radius: 1.2rem;
  padding: 2.4rem;
  margin: 0 1.6rem;

  color: $white;
  font-size: 1.4rem;
  font-style: normal;
  line-height: 2.1rem;
}