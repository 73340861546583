@import "app/styles/assets/scss/variables";
@import "app/styles/assets/scss/colors";

.no_touch {
  > * {
    touch-action: none !important;
    pointer-events: none !important;
    transform: none !important;
  }
}

.pswp__bg {
  opacity: 1 !important;
  background: #2B2B2B !important;
}

.pswp__content {
  width: 100vw !important;
  position: absolute;
  top: 6rem;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: var(--sait);
  height: calc(100vh - 6rem) !important;
}

.pswp__top-bar {
  top: var(--sait) !important;
}

.pswp__counter {
  margin-inline-start: 0 !important;
  position: absolute !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  align-self: center;
  margin-top: 0 !important;
  height: auto !important;

  font-size: 1.6rem !important;
  font-style: normal !important;
  font-weight: 800 !important;
  line-height: 2.2rem !important;
}

.pswp__button--close {
  margin-right: 1.6rem !important;

  & svg {
    display: none;
  }
}

.pswp__button--close::before {
  content: 'close' !important;
  position: absolute !important;
  top: calc(50% - 1.4rem) !important;
  right: 0 !important;
  font-family: $iconFont2 !important;
  font-size: 2.4rem !important;
  width: 2.4rem !important;
  height: 2.4rem !important;
  color: $white !important;
}


.pswp__zoom-wrap {
  transform: none !important;
}

.pswp__button--arrow--next, .pswp__button--arrow--prev {
  display: none !important;
}
